import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

// import { linkResolver } from '../linkResolver'
import linkResolver from '../utils/linkResolver'

import Layout from '../components/Layout'

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!'

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  )
}

const linkResolverWrapper = ({ field, value, node }) => doc => linkResolver(doc)

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'preoswebsite',
  linkResolver: linkResolverWrapper,
})
